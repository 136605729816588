import { App, defineAsyncComponent } from 'vue'

const register = (app: App<Element>): void => {
  app
    .component(
      'LpIcon',
      defineAsyncComponent(() => import('@/app/components/icons/index.vue'))
    )
    .component(
      'LpButton',
      defineAsyncComponent(() => import('@/app/components/forms/LpButton.vue'))
    )
    .component(
      'LpBottomSheet',
      defineAsyncComponent(() => import('@/app/components/forms/BottomSheet.vue'))
    )
    .component(
      'LpDialog',
      defineAsyncComponent(() => import('@/app/components/forms/Dialog.vue'))
    )
    .component(
      'PoweredBy',
      defineAsyncComponent(() => import('@/app/views/buyer-page/components/powered-by.vue'))
    )
    .component(
      'LpTooltip',
      defineAsyncComponent(() => import('@/app/components/misc/Tooltip.vue'))
    )
}

export default {
  register,
}
