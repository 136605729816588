import 'reflect-metadata'
import App from './App.vue'
import { createApp } from 'vue'
import { Notify, Quasar, Loading } from 'quasar'
import { createPinia } from 'pinia'
import piniaPluginPersistedState from 'pinia-plugin-persistedstate'
import * as Sentry from '@sentry/vue'
import './style.css'
import 'quasar/src/css/index.sass'
import '@quasar/extras/material-icons/material-icons.css'

import { Vue3Mq } from 'vue3-mq'
import VueGtag from 'vue-gtag'
import router from '@/app/router'
import { registerSW } from 'virtual:pwa-register'
import { initRollout } from '@/feature-flags'
import Hotjar from 'vue-hotjar'
import DiklikComponents from '@/app/components'

if ('serviceWorker' in navigator) {
  const updateSW = registerSW({
    onNeedRefresh() {
      Notify.create({
        type: 'info',
        timeout: 3000,
        position: 'top-right',
        message: 'Terdapat pembaharuan fitur.',
      })
      setTimeout(async () => {
        await updateSW(true)
      }, 3000)
    },
  })
}
const app = createApp(App)
DiklikComponents.register(app)
const pinia = createPinia()
pinia.use(piniaPluginPersistedState)

app.use(Quasar, {
  plugins: {
    Notify,
    Loading,
  },
  config: {
    brand: {
      primary: '#FEC52E',
    },
  },
})
app.use(pinia)
app.use(router)
app.use(Vue3Mq, {
  preset: 'tailwind',
})
app.use(
  VueGtag,
  {
    pageTrackerScreenviewEnabled: true,
    config: { id: import.meta.env.VITE_GA_MEASUREMENT_ID },
  },
  router
)
app.use(Hotjar, {
  id: import.meta.env.VITE_APP_HOTJAR_ID,
  isProduction: import.meta.env.VITE_APP_ENVIRONMENTS === 'production',
})

if (
  import.meta.env.VITE_SENTRY_DSN &&
  import.meta.env.VITE_SENTRY_DSN.trim() !== '' &&
  import.meta.env.VITE_SENTRY_TARGET &&
  import.meta.env.VITE_SENTRY_TARGET.trim() !== ''
) {
  const traceSampleRate = parseFloat(import.meta.env.VITE_SENTRY_TRACE_SAMPLE_RATE || '0.01')
  const sampleRate = parseFloat(import.meta.env.VITE_SENTRY_SAMPLE_RATE || '0.45')

  // REF: https://docs.sentry.io/platforms/javascript/legacy-sdk/tips/
  Sentry.init({
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
      // Generic error code from errors outside the security sandbox
      // Avast extension error
      '_avast_submit',
      "'text/html' is not a valid JavaScript MIME type",
      'The element has no supported sources',
      'Load failed'
    ],
    denyUrls: [
      // Google Adsense
      /pagead\/js/i,
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    tracePropagationTargets: [import.meta.env.VITE_SENTRY_TARGET, /^\//],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: traceSampleRate, // Set tracesSampleRate to 0.0 to disable transaction events
    sampleRate: sampleRate, // 45% of events error will be sent for production
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    // replaysSessionSampleRate: 0.1,
    // replaysOnErrorSampleRate: 1.0,
    environment: import.meta.env.VITE_APP_ENV,
    // debug: typeof import.meta.env.mode === 'undefined', // Only debug in development local
    beforeBreadcrumb(breadcrumb, hint) {
      if (
        breadcrumb.category === 'console' &&
        (breadcrumb.message?.includes('[vue-gtag]') ||
          breadcrumb.message?.includes('Multiple GoTrueClient') ||
          breadcrumb.message?.includes('[Vue warn]') ||
          breadcrumb.message?.includes('[Meta Pixel]') ||
          breadcrumb.message?.includes('[TikTok Pixel]') ||
          breadcrumb.message?.includes('appJsBridge already exist!') ||
          breadcrumb.level === 'debug')
      ) {
        return null
      }

      // get aria label from element
      if (breadcrumb.category === 'ui.click' || breadcrumb.category === 'ui.input') {
        // get hint element
        const { target } = hint?.event
        if (target.ariaLabel || target.placeholder) {
          breadcrumb.message = target.ariaLabel || target.placeholder
        }

        if (breadcrumb.category === 'ui.input') {
          breadcrumb.message += ` = ${target.value}`
        }
      }

      return breadcrumb
    },
    beforeSend(event, hint) {
      if (event.type && event.type !== 'exception') {
        return null // Ignore all events that are not exceptions
      }

      // // uncomment this to ignore with specific error message and hint
      // if (hint?.originalException && hint.originalException.constructor.name === 'CustomError') {
      //   const customError = hint.originalException as CustomError
      //   let hasSpecifiedMessage = false
      //   if (event.extra && event.extra.hasOwnProperty('errorResponse')) {
      //     // Check if any value in event.extra has a message that contains the specified string
      //      hasSpecifiedMessage = Object.values(event.extra).some((value: any) =>
      //       typeof value.message === 'string' && value.message.includes('JSON object requested, multiple (or no) rows returned')
      //     );
      //   }
      //   const listIgnoreError = ['ad-integrations']
      //   if (hasSpecifiedMessage && listIgnoreError.some(error => customError.message.includes(error)) && customError.hint === 'error_from_supabase_client_fetch_api') {
      //     return null
      //   }
      // }

      if (!event.request?.data) {
        return event
      }

      // cut off request body if it's too long, as it could be big
      const size = JSON.stringify(event.request.data).length
      if (size > 500000) {
        event.request = event.request.data.slice(0, 500000)
      }

      return event
    },
  })
}

app.config.errorHandler = (err, _vm, info) => {
  Sentry.setExtra('info_message_app_errorHandler', info)
  Sentry.captureException(err)
}

initRollout().then(() => {
  app.mount('#app')
})

// @ts-ignore
if (/eruda=true/.test(window.location.href)) {
  const erudaScript = document.createElement('script')
  erudaScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/eruda/3.0.1/eruda.js'
  document.body.appendChild(erudaScript)
  erudaScript.onload = function () {
    // @ts-ignore
    eruda.init()
  }
}
