import { RouteRecordRaw } from 'vue-router'

export const routes: RouteRecordRaw[] = [
  // keep this as a last one to catch all buyer link checkout
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/app/layouts/buyer-layout.vue'),
    children: [
      {
        path: ':id',
        name: 'checkout-buyer-page',
        component: () => import('@/app/views/buyer-page/index.vue'),
      },
      {
        path: 'tos',
        name: 'tos',
        component: () => import('@/app/views/tos.vue'),
      },
      {
        path: '',
        name: 'landing',
        component: () => import('@/app/views/landing-page/index.vue'),
      },
      {
        path: 'cart',
        name: 'cart',
        component: () => import('@/app/views/buyer-page/cart/index.vue'),
      },
      {
        path: 'cart/:unique',
        name: 'cart-from-draft',
        component: () => import('@/app/views/buyer-page/cart/index.vue'),
      },
      {
        path: 'products/:sellerId',
        name: 'product-list-page',
        component: () => import('@/app/views/buyer-page/product-list-v2.vue'),
      },
      {
        path: 'store/:sellerSlug/:tab?',
        name: 'store',
        component: () => import('@/app/views/buyer-page/product-list-v2.vue'),
      },
      // keep this as a last one to catch all error route and redirect to default page
      {
        path: ':pathMatch(.*)*',
        redirect: '/',
      },
    ],
  },
]
